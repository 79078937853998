/*
 * @Description:
 * @Author: ZY
 * @Date: 2021-01-08 19:32:52
 * @LastEditors: scy😊
 * @LastEditTime: 2021-01-23 15:55:22
 */

import Layout from '@/layout/Index.vue'
import { shallowRef } from 'vue'
import { RouteRecordRaw } from 'vue-router'
const PlayerManageRouter: Array<RouteRecordRaw> = [
    {
        path: '/player_manage',
        component: shallowRef(Layout),
        redirect: '/player_manage/player_query',
        meta: {
            title: 'player_manage',
            icon: '#iconquanxian',
        },
        children: [
            {
                path: 'player_query',
                component: () => import(/* webpackChunkName: "player_query" */ '@/views/player_manage/PlayerQuery.vue'),
                name: 'player_query',
                meta: {
                    title: 'player_query',
                }
            },
            {
                path: 'player_bagitemlog',
                component: () => import(/* webpackChunkName: "player_bagitemlog" */ '@/views/player_manage/BagItemLog.vue'),
                name: 'player_bagitemlog',
                meta: {
                    title: 'player_bagitemlog'
                }
            },

        ]
    }
]
export default PlayerManageRouter

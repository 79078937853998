/*
 * @Description: 游戏订单管理
 * @Author: scy
 * @Date: 2021-01-21 20:13:03
 * @LastEditors: scy
 * @LastEditTime: 2021-01-21 21:24:27
 */
import Layout from '@/layout/Index.vue'
import { shallowRef } from 'vue'
import { RouteRecordRaw } from 'vue-router'
const DataOrderRouter: Array<RouteRecordRaw> = [

  {
    path: '/data_order',
    component: shallowRef(Layout),
    redirect: '/data_order/data_orderincome',
    meta: {
      title: 'data_order',
      icon: '#iconexcel'
    },
    children: [
      {
        path: 'data_orderincome',
        component: () => import(/* webpackChunkName: "data_orderincome" */ '@/views/data_order/DataOrderIncome.vue'),
        name: 'data_orderincome',
        meta: { title: 'data_orderincome' }
      },
      {
        path: 'data_ordersearch',
        component: () => import(/* webpackChunkName: "data_ordersearch" */ '@/views/data_order/DataOrderSearch.vue'),
        name: 'data_ordersearch',
        meta: { title: 'data_ordersearch' }
      },
      {
        path: 'data_orderfaillog',
        component: () => import(/* webpackChunkName: "data_orderfaillog" */ '@/views/data_order/DataOrderFailLog.vue'),
        name: 'data_orderfaillog',
        meta: { title: 'data_orderfaillog' }
      },
     
    ]
  }
]

export default DataOrderRouter

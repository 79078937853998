/*
 * @Description: 网络配置常量
 * @Author: ZY
 * @Date: 2020-12-08 13:34:37
 * @LastEditors: ZY
 * @LastEditTime: 2020-12-08 13:39:56
 */

export enum InfoShowType {
    LOG,
    NOTIFICATION,
    TOAST
}


export enum ERoleType {
    /// <summary>
    /// 玩家
    /// </summary>
    Player = 0,
    /// <summary>
    /// 客户端发送GM指令
    /// </summary>
    PlayerGm = 1,
    /// <summary>
    /// 后台数据查看
    /// </summary>
    DataView = 2,
    /// <summary>
    /// 活动编辑
    /// </summary>
    ActivityEditer = 4,
    /// <summary>
    /// 修改玩家数据
    /// </summary>
    DataEditer = 8,
    /// <summary>
    /// 服务器管理
    /// </summary>
    ServerManager = 16,
    /// <summary>
    /// 超级管理
    /// </summary>
    admin = 2048,
}


export enum EPayType { 
    AliPay = 1000,
    WeChat = 2000,
    Paypal = 3000,
    YooMoney = 4000,
}

export enum EPayOrderState
{
    CreateSuccess = 1,
    CreateFail = 2,
    CreateError = 4,
    PaySuccess = 8,
    PayFail = 16,
    PayWait = 32,
    PayAddItemSuccess = 64,
    PayAddItemFail = 128,
}

export function GetEnumObject(e: { [k: string]: string | number }) {
    let r: { [k: string]: number } = {};
    for (let key in e) {
        if (typeof e[key] == "number") {
            r[key] = e[key] as number;
        }
    }
    return r;
}
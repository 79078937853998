/*
 * @Description: app actions
 * @Author: ZY
 * @Date: 2020-12-23 10:25:37
 * @LastEditors: scy😊
 * @LastEditTime: 2021-01-29 08:46:37
 */
import { User_GMGetUserInfo, User_LoginRequest } from '@/apis/user'
import { ERoleType } from '@/constant/network'
import router, { resetRouter } from '@/router'
import { RootState, useStore } from '@/store'
import { removeToken, setToken } from '@/utils/cookies'
import { ElMessage } from 'element-plus'
import { RouteRecordRaw } from 'vue-router'
import { ActionContext, ActionTree } from 'vuex'
import { PermissionActionType } from '../permission/action-types'
import { UserActionTypes } from './action-types'
import { UserMutationTypes } from './mutation-types'
import { Mutations } from './mutations'
import { UserState, state } from './state'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<UserState, RootState>, 'commit'>

export interface Actions {
  [UserActionTypes.ACTION_LOGIN](
    { commit }: AugmentedActionContext,
    userInfo: { username: string, password: string }
  ): void
  [UserActionTypes.ACTION_RESET_TOKEN](
    { commit }: AugmentedActionContext
  ): void
  [UserActionTypes.ACTION_GET_USER_INFO](
    { commit }: AugmentedActionContext
  ): void
  [UserActionTypes.ACTION_CHANGE_ROLES](
    { commit }: AugmentedActionContext, role: string
  ): void
  [UserActionTypes.ACTION_LOGIN_OUT](
    { commit }: AugmentedActionContext,
    label: string
  ): void
}

export const actions: ActionTree<UserState, RootState> & Actions = {
  async [UserActionTypes.ACTION_LOGIN](
    { commit }: AugmentedActionContext,
    userInfo: { username: string, password: string }
  ) {
    let { username, password } = userInfo
    username = username.trim()
    const res = await User_LoginRequest({ Account: username, Password: password });
    if (res?.Error === 0 && res.Token) {
      const token = "Bearer " + res.Token;
      setToken(token)
      commit(UserMutationTypes.SET_TOKEN, token)
      ElMessage.success("登录成功")
    }
    else {
      ElMessage.error(res?.Message || "登录失败")
    }
  },

  [UserActionTypes.ACTION_RESET_TOKEN](
    { commit }: AugmentedActionContext) {
    removeToken()
    commit(UserMutationTypes.SET_TOKEN, '')
    commit(UserMutationTypes.SET_ROLES, [])
  },

  async [UserActionTypes.ACTION_GET_USER_INFO](
    { commit }: AugmentedActionContext
  ) {
    if (state.token === '') {
      throw Error('token is undefined!')
    }
    await User_GMGetUserInfo().then((res) => {
      if (res?.Error === 0) {
        const data = JSON.parse(res.Message) as IGmCharacterDataComponent;
        commit(UserMutationTypes.SET_ROLES, data.Roles.map((k) => { return ERoleType[k] }));
        commit(UserMutationTypes.SET_NAME, data.Account)
        commit(UserMutationTypes.SET_AVATAR, data.Avatar)
        commit(UserMutationTypes.SET_INTRODUCTION, data.Description)
        commit(UserMutationTypes.SET_EMAIL, data.Email)
        return res
      } else {
        ElMessage.error('Verification failed, please Login again.')
        removeToken()
        commit(UserMutationTypes.SET_TOKEN, '')
        commit(UserMutationTypes.SET_ROLES, [])
        resetRouter()
      }
    })
  },

  async [UserActionTypes.ACTION_CHANGE_ROLES](
    { commit }: AugmentedActionContext,
    role: string
  ) {
    const token = role + '-token'
    const store = useStore()
    commit(UserMutationTypes.SET_TOKEN, token)
    setToken(token)
    await store.dispatch(UserActionTypes.ACTION_GET_USER_INFO, undefined)
    store.dispatch(PermissionActionType.ACTION_SET_ROUTES, state.roles)
    store.state.permission.dynamicRoutes.forEach((item: RouteRecordRaw) => {
      router.addRoute(item)
    })
  },

  [UserActionTypes.ACTION_LOGIN_OUT](
    { commit }: AugmentedActionContext,
    label: string
  ) {
    console.log(commit)
    removeToken()
    commit(UserMutationTypes.SET_TOKEN, '')
    commit(UserMutationTypes.SET_ROLES, [])
    resetRouter()
  }
}

/*
 * @Description: 游戏管理
 * @Author: scy
 * @Date: 2021-01-21 20:13:03
 * @LastEditors: scy
 * @LastEditTime: 2021-01-21 21:24:27
 */
import Layout from '@/layout/Index.vue'
import { shallowRef } from 'vue'
import { RouteRecordRaw } from 'vue-router'
const GameManageRouter: Array<RouteRecordRaw> = [

  {
    path: '/game_manage',
    component: shallowRef(Layout),
    redirect: '/game_manage/game_notice',
    meta: {
      title: 'game_manage',
      icon: '#iconexcel'
    },
    children: [
      {
        path: 'game_notice',
        component: () => import(/* webpackChunkName: "game_notice" */ '@/views/game_manage/GameNotice.vue'),
        name: 'game_notice',
        meta: { title: 'game_notice' }
      },
      {
        path: 'game_serverkey',
        component: () => import(/* webpackChunkName: "game_serverkey" */ '@/views/game_manage/GameServerKey.vue'),
        name: 'game_serverkey',
        meta: { title: 'game_serverkey' }
      },
      {
        path: 'game_mail',
        component: () => import(/* webpackChunkName: "game_mail" */ '@/views/game_manage/GameMail.vue'),
        name: 'game_mail',
        meta: { title: 'game_mail' }
      },
      {
        path: 'game_clienterror',
        component: () => import(/* webpackChunkName: "game_clienterror" */ '@/views/game_manage/GameError.vue'),
        name: 'game_clienterror',
        meta: { title: 'game_clienterror' }
      },
      {
        path: 'game_clientsuggest',
        component: () => import(/* webpackChunkName: "game_clientsuggest" */ '@/views/game_manage/GameSuggest.vue'),
        name: 'game_clientsuggest',
        meta: { title: 'game_clientsuggest' }
      },
    ]
  }
]

export default GameManageRouter

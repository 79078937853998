/*
 * @Description: pdf打印
 * @Author: scy
 * @Date: 2021-01-21 20:13:03
 * @LastEditors: scy😊
 * @LastEditTime: 2021-01-25 11:36:25
 */
import Layout from '@/layout/Index.vue';
import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
const PdfRouter: Array<RouteRecordRaw> = [
  {
    path: '/pdf',
    component: shallowRef(Layout),
    redirect: '/pdf/index',
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "pdf" */ '@/views/pdf/Index.vue'),
        name: 'PDF',
        meta: {
          title: 'pdf',
          icon: '#iconpdf'
        }
      }
    ]
  },
  {
    path: '/pdf-download-example',
    component: () => import(/* webpackChunkName: "pdf" */ '@/views/pdf/Download.vue'),
    meta: { hidden: true }
  }
]
PdfRouter.length = 0;
export default PdfRouter

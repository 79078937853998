
import { User_GMLoginOut } from '@/apis/user'
import BreadCrumb from '@/components/bread-crumb/Index.vue'
import Hamburger from '@/components/hamburger/Index.vue'
import LangSelect from '@/components/lang_select/Index.vue'
import Screenfull from '@/components/screenfull/Index.vue'
import SizeSelect from '@/components/size_select/Index.vue'

import { useStore } from '@/store'
import { AppActionTypes } from '@/store/modules/app/action-types'
import { UserActionTypes } from '@/store/modules/user/action-types'
import { computed, reactive, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
export default {
  components: {
    BreadCrumb,
    Hamburger,
    Screenfull,
    LangSelect,
    SizeSelect
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()
    const sidebar = computed(() => {
      return useStore().state.app.sidebar
    })
    const device = computed(() => {
      return useStore().state.app.device.toString()
    })
    const avatar = computed(() => {
      return useStore().state.user.avatar
    })
    const state = reactive({
      toggleSideBar: () => {
        useStore().dispatch(AppActionTypes.ACTION_TOGGLE_SIDEBAR, false)
      },
      logout: async () => {
        let cbmsg = await User_GMLoginOut();
        useStore().dispatch(UserActionTypes.ACTION_LOGIN_OUT, "")
        router.push(`/login?redirect=${route.fullPath}`).catch(err => {
          console.warn(err)
        })
      }
    })
    return {
      sidebar,
      device,
      avatar,
      ...toRefs(state),
      t
    }
  }
}

/*
 * @Description: 粘贴板
 * @Author: scy
 * @Date: 2021-01-21 20:13:03
 * @LastEditors: scy
 * @LastEditTime: 2021-01-21 21:24:27
 */
import Layout from '@/layout/Index.vue';
import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
const ProfileRouter: Array<RouteRecordRaw> = [
  {
    path: '/clipboard',
    component: shallowRef(Layout),
    redirect: 'noredirect',
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "clipboard" */ '@/views/clipboard/Index.vue'),
        name: 'Clipboard',
        meta: {
          title: 'clipboard',
          icon: '#iconcopy'
        }
      }
    ]
  }
]
ProfileRouter.length = 0;
export default ProfileRouter

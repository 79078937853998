/*
 * @Description: excel相关
 * @Author: scy
 * @Date: 2021-01-21 20:13:03
 * @LastEditors: scy
 * @LastEditTime: 2021-01-21 21:24:27
 */
import Layout from '@/layout/Index.vue'
import { shallowRef } from 'vue'
import { RouteRecordRaw } from 'vue-router'
const ServerManageRouter: Array<RouteRecordRaw> = [

  {
    path: '/server_manage',
    component: shallowRef(Layout),
    redirect: '/server_manage/server_process',
    meta: {
      title: 'server_manage',
      icon: '#iconexcel'
    },
    children: [
      {
        path: 'server_process',
        component: () => import(/* webpackChunkName: "server_process" */ '@/views/server_manage/ServeProcess.vue'),
        name: 'server_process',
        meta: { title: 'server_process' }
      },
      {
        path: 'server_log',
        component: () => import(/* webpackChunkName: "server_log" */ '@/views/server_manage/ServerLog.vue'),
        name: 'server_log',
        meta: { title: 'server_log' }
      },
      {
        path: 'server_zone',
        component: () => import(/* webpackChunkName: "server_zone" */ '@/views/server_manage/ServerZone.vue'),
        name: 'server_zone',
        meta: { title: 'server_zone' }
      },
    
    ]
  }
]

export default ServerManageRouter

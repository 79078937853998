/*
 * @Description: 游戏数据管理
 * @Author: scy
 * @Date: 2021-01-21 20:13:03
 * @LastEditors: scy
 * @LastEditTime: 2021-01-21 21:24:27
 */
import Layout from '@/layout/Index.vue'
import { shallowRef } from 'vue'
import { RouteRecordRaw } from 'vue-router'
const DataGameRouter: Array<RouteRecordRaw> = [

  {
    path: '/data_game',
    component: shallowRef(Layout),
    redirect: '/data_game/data_playernew',
    meta: {
      title: 'data_game',
      icon: '#iconexcel'
    },
    children: [
      {
        path: 'data_playernew',
        component: () => import(/* webpackChunkName: "data_playernew" */ '@/views/data_game/DataPlayerNew.vue'),
        name: 'data_playernew',
        meta: { title: 'data_playernew' }
      },
      {
        path: 'data_playeronline',
        component: () => import(/* webpackChunkName: "data_playeronline" */ '@/views/data_game/DataPlayerOnline.vue'),
        name: 'data_playeronline',
        meta: { title: 'data_playeronline' }
      },
      {
        path: 'data_playerretained',
        component: () => import(/* webpackChunkName: "data_playerretained" */ '@/views/data_game/DataPlayerRetained.vue'),
        name: 'data_playerretained',
        meta: { title: 'data_playerretained' }
      },
      {
        path: 'data_shopbuy',
        component: () => import(/* webpackChunkName: "data_shopbuy" */ '@/views/data_game/DataShopBuy.vue'),
        name: 'data_shopbuy',
        meta: { title: 'data_shopbuy' }
      },
      {
        path: 'data_gamerecord',
        component: () => import(/* webpackChunkName: "data_gamerecord" */ '@/views/data_game/DataGameRecord.vue'),
        name: 'data_gamerecord',
        meta: { title: 'data_gamerecord' }
      },
      {
        path: 'data_gamebattle',
        component: () => import(/* webpackChunkName: "data_gamebattle" */ '@/views/data_game/DataGameBattle.vue'),
        name: 'data_gamebattle',
        meta: { title: 'data_gamebattle' }
      },
    ]
  }
]

export default DataGameRouter

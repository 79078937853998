
import { useStore } from '@/store'
import { computed, defineComponent, ref } from 'vue'
export default defineComponent({
  props: {
    buttonTop: {
      type: Number,
      default: 250
    },
   
  },
  setup() {
    const show = ref(false)
    const store = useStore()
    const theme = computed(() => {
      return store.state.settings.theme
    })
    return {
      show,
      theme
    }
  }
})

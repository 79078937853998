/*
 * @Description: 用户相关接口
 * @Author: ZY
 * @Date: 2020-12-28 14:40:50
 * @LastEditors: scy😊
 * @LastEditTime: 2021-01-20 10:17:09
 */
import https from '@/utils/https'
import { ContentType, Method } from 'axios-mapper'

export const User_LoginRequest = (data: IC2G_GMLogin) => {
  return https(false).request<IG2C_GMLogin>('/GMLogin', Method.POST, data, ContentType.json)
}
export const User_GMLoginOut = () => {
  return https().request<IHttpResponse>('/GMLoginOut', Method.POST, {}, ContentType.json)
}

export const User_ChangePassword = (data: IC2G_GMChangePassword) => {
  return https().request<IHttpResponse>('/GMChangePassword', Method.POST, data, ContentType.json)
}

export const User_EditAccount = (data: IC2G_GMEditAccount) => {
  return https().request<IHttpResponse>('/GMEditAccount', Method.POST, data, ContentType.json)
}

export const User_GMGetAllUserInfo = () => {
  return https().request<IHttpResponse>("/GMGetAllUserInfo", Method.POST, {}, ContentType.json)
}

export const User_GMRegiste = (data: IC2G_GMRegiste) => {
  return https().request<IHttpResponse>("/GMRegiste", Method.POST, data, ContentType.json)
}

export const User_GMGetUserInfo = () => {
  return https().request<IHttpResponse>('/GMGetUserInfo', Method.POST, {}, ContentType.json)
}

